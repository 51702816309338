<template>
  <div>
    <span v-print="printConfig" ref="bulkPrintButton"> </span>
    <div class="print" v-bind:id="printConfig.id">
      <div v-for="(order, index) in data" v-bind:key="order.id">
        <OrderDetailTab
          class="mb-3"
          v-bind:order="order"
          v-bind:site-logo="siteLogo"
        />
        <InvoiceTab v-bind:order="order" />
        <div v-if="index !== data.length - 1" class="print__page-break"></div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderDetailTab from "../OrderDetail/views/DetailTab/Index.vue";
import InvoiceTab from "../OrderDetail/views/InvoiceTab/Index.vue";
export default {
  name: "BulkOrderPrint",
  data() {
    return {
      printConfig: {
        id: "bulkPrint",
        popTitle: "",
      },
    };
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    siteLogo: {
      default: null,
    },
  },
  components: {
    OrderDetailTab,
    InvoiceTab,
  },
};
</script>